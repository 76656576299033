import IframeResizer from "iframe-resizer-react";
import * as React from "react";
import Layout from "../../components/layout";
import { mainContent, introSection } from "../subpage.module.scss";

const SendinblueHtmlIframePage = () => {
  return (
    <Layout pageTitle="Anmeldung DMEA 2023" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <IframeResizer
            log
            src="/sendinblue/html-iframe-content.html"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{ width: "1px", minWidth: "100%" }}
          />
        </section>
      </main>
    </Layout>
  );
};

export default SendinblueHtmlIframePage;
